@import './abstracts/_mixins';
@import './abstracts/_variables';
@import './components/title';
@import './components/button';
@import './components/input';

.notfound {
  margin: 3rem 0 2rem 0;

  &__title {
    @include h2();
    width: 100%;
    padding: 2rem 0;
    background-color: var(--toc-grey);
  }

  &__image {
    @include flex-row-justify(center);
    width: 100%
  }

  &__return {
    button {
      @include button-standart(primary);
    }
  }
}



.best-sellers {
  .products_block {
    .title_block {
      margin-bottom: 0;
    }
  }

  .products-block {
    &>li {
      padding: 2rem;
      margin-bottom: 0;

      .products-block-image {
        min-width: 130px;
        min-height: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .product-content {
        h5 {
          top: inherit !important;
        }
      }
    }
  }

  .lnk {
    display: flex;
    justify-content: center;

    .btn {
      @include button-cta(primary);
      margin-top: 2rem;

      &>span {
        border: none;

        &:hover {
          background: inherit;
        }
      }
    }
  }
}